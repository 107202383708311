<template>
  <div class="landing-container">
    <!-- Hero Section -->
    <div class="index-carousel">
      <div class="main masthead position-relative">
        <div class="masthead-gradient-overlay"></div>
        
        <div class="content-wrapper">
          <div class="container">
            <div class="row gx-5 align-items-center">
              <!-- Left Side Content -->
              <div class="col-lg-5 col-md-6">
                <div class="content-section">
                 
                  
                  <h1 class="headline">
                    Transform Your Product Photos into Stunning Marketing Content in Seconds!
                  </h1>
                  <div class="briks-gradient-bar mb-4"></div>
                  <p class="description">
                    Create scroll-stopping social media content, save thousands on photoshoots, and automate your content creation—all without any upfront cost!
                  </p>

                  <div class="features-list">
                    <div class="feature-item">
                      <span class="feature-dot"></span>
                      <span>Transform product photos into lifestyle content in seconds</span>
                    </div>
                    <div class="feature-item">
                      <span class="feature-dot"></span>
                      <span>Generate unlimited marketing visuals for all channels</span>
                    </div>
                    <div class="feature-item">
                      <span class="feature-dot"></span>
                      <span>Start free - no credit card required</span>
                    </div>
                  </div>

                  <b-button 
                    @click="navigateToCreateAgent"
                    class="start-button"
                  >
                    Start Creating
                    <i class="fas fa-arrow-right ms-2"></i>
                  </b-button>
                </div>
              </div>
              
              <!-- Right Side Carousel -->
              <div class="col-lg-7 col-md-6">
                <div class="carousel-wrapper">
                  <carousel 
                    :autoplay="true" 
                    :autoplay-timeout="3000" 
                    :loop="true" 
                    :navigation-enabled="true"
                    :pagination-enabled="true"
                    :per-page="1"
                    class="modern-carousel"
                  >
                    <slide v-for="(pair, index) in imagePairs" :key="index">
                      <div class="image-comparison">
                        <div class="comparison-container">
                          <div class="image-item">
                            <img :src="pair.originalImage" alt="Original Image" class="comparison-image" />
                            <div class="image-label">Original</div>
                          </div>
                          <div class="image-item">
                            <img :src="pair.transformedImage" alt="Transformed Image" class="comparison-image" />
                            <div class="image-label">Transformed</div>
                          </div>
                        </div>
                      </div>
                    </slide>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- How It Works Section -->
    <section class="how-it-works">
      <div class="container">
        <div class="section-header text-center">
          <h2 class="section-title">Create Stunning Visuals in 3 Simple Steps!</h2>
          <div class="gradient-bar mx-auto"></div>
        </div>
        
        <div class="steps-container">
          <div class="step-grid">
            <!-- Step 1 -->
            <div class="step-card">
              <div class="step-number">1</div>
              <div class="step-image-container">
                <img src="@/assets/images/product_discovery.svg" alt="Upload or Connect Store" class="step-image" />
              </div>
              <h3 class="step-title">Upload Product Images </h3>
              <p class="step-description">
                Simply upload individual product images or seamlessly connect your e-commerce store for bulk imports
              </p>
            </div>

            <!-- Step 2 -->
            <div class="step-card">
              <div class="step-number">2</div>
              <div class="step-image-container">
                <img src="@/assets/images/6b.jpg" alt="AI Processing" class="step-image" />
              </div>
              <h3 class="step-title">Build Your Creative Agent</h3>
              <p class="step-description">
                Our AI engine processes your product images to build a brand new creative agent capable of creating diverse, on-brand marketing visuals and content
              </p>
            </div>

            <!-- Step 3 -->
            <div class="step-card">
              <div class="step-number">3</div>
              <div class="step-image-container">
                <img src="@/assets/images/7.jpg" alt="Generate Visuals" class="step-image" />
              </div>
              <h3 class="step-title">Generate Marketing Content</h3>
              <p class="step-description">
                 Just describe what you want, and our creative agents delivers both visuals and text content in seconds
              </p>
            </div>
          </div>

          <div class="text-center mt-3">
            <b-button 
              @click="navigateToCreateAgent" 
              class="start-button"
            >
              Start Creating
              <i class="fas fa-arrow-right ms-2"></i>
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'ModernLandingPage',
  components: {
    Carousel,
    Slide,
    BButton
  },
  data() {
    return {
      imagePairs: [
 
        {
          originalImage: 'https://media.briks.ai/images/vitage-car2.jpg',
          transformedImage: 'https://media.briks.ai/images/image-98756016-b4ae-4f2b-9820-e87fe318b86b.png',
        }, 
        {
          originalImage: 'https://media.briks.ai/images/chair_image_resized.png',
          transformedImage: 'https://media.briks.ai/images/image-31cc2d26-cc42-4579-a535-2dac75807266.png',
        },  
       
        {
          originalImage: 'https://media.briks.ai/images/bottle.png',
          transformedImage: 'https://media.briks.ai/images/image-682954cf-1db8-4deb-9488-d59b4c6aa9c1.jpg',
        },  
        {
          originalImage: 'https://media.briks.ai/images/bottle.png',
          transformedImage: 'https://media.briks.ai/images/image-26fbb20d-3bc4-437c-8078-f5b645980d4e.jpg',
        },  
        {
          originalImage: 'https://media.briks.ai/images/bottle.jpg',
          transformedImage: 'https://media.briks.ai/images/water_bottle.jpg',
        },
        {
          originalImage: 'https://media.briks.ai/images/brown_dress.png',
          transformedImage: 'https://media.briks.ai/images/image-25bdf985-e77a-4f40-8155-2f1ac0d172c2.png',
        },
        {
          originalImage: 'https://media.briks.ai/images/blue_dress.jpg',
          transformedImage: 'https://media.briks.ai/images/blue_dress_changed.png',
        },
  
        {
          originalImage: 'https://media.briks.ai/images/IMG_7759.jpg',
          transformedImage: 'https://media.briks.ai/images/image-7ad11942-74f5-47e8-9182-02781c391382.jpg',
        },
      
        {
          originalImage: 'https://media.briks.ai/images/430789649_7206795366100161_2780917370850811012_n.jpg',
          transformedImage: 'https://media.briks.ai/images/image-cec793c1-2e74-498d-977d-5aba7b7c539f.jpg',
        },
        {
          originalImage: 'https://media.briks.ai/images/430789649_7206795366100161_2780917370850811012_n.jpg',
          transformedImage: 'https://media.briks.ai/images/image-0b534715-c8e5-4530-9397-6cbbecbd17cf.png',
        },  
        {
          originalImage: 'https://media.briks.ai/images/chair_image_resized.png',
          transformedImage: 'https://media.briks.ai/images/image-73e49c59-c068-496f-be35-4f1f8c039d31.png',
        }, 

      ],
    };
  },
  methods: {
    navigateToCreateAgent() {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        this.$bvModal.show("modal-login");
        return;
      }
      this.$router.push({ name: 'products' });
    }
  }
};
</script>

<style lang="scss" scoped>
.landing-container {
  min-height: 100vh;
  background-color: #000000;
}

.masthead {
  position: relative;
  padding: 3rem 0;
  padding-bottom: 0rem; 
  background: url('~@/assets/images/banner/bg.png'); 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.masthead-title {
  color: white; 
  font-size: 2.4rem; 
  padding-left: 30px; 
  margin-bottom: 20px; 
  line-height: 1.2;
  white-space: normal;
  overflow: visible;
}

.masthead-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(130deg, rgba(189, 41, 41, 0.98) 0%, rgba(0,0,0,0.95) 0%);
}

.briks-gradient-bar {
  height: 6px;
  width: 100%;
  max-width: 300px;
  background: linear-gradient(90deg, #DC123E 50%, #FFFFFF 80%);
  border-radius: 3px;
}

.content-wrapper {
  position: relative;
  margin: 2.5rem;
  z-index: 1;
}

.headline {
  font-size: 2.8rem;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 1.2;
  margin: 1rem 0;
  padding-top: 0px;
}

.description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  margin-top: -15px;
}

.features-list {
  margin: 0rem 0;
  padding-bottom: 25px;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  
  .feature-dot {
    width: 6px;
    height: 6px;
    background: linear-gradient(90deg, #4b9fff 80%, #9B6EF3 30%);
    border-radius: 50%;
    margin-right: 1rem;
  }
}

.start-button {
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 0%);
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border-radius: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
  }
}

.carousel-wrapper {
  padding: 1rem;
  border-radius: 1rem;
}

.image-comparison {
  .comparison-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.image-item {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  
  .comparison-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 0.5rem;
  }
  
  .image-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }
}

// Responsive styles
@media (max-width: 768px) {
  .headline {
    font-size: 2rem;
  }
  
  .content-section {
    padding: 0 1rem;
    margin-bottom: 2rem;
    
  }
  
  .carousel-wrapper {
    padding: 0.5rem;
  }
  
  .comparison-container {
    grid-template-columns: 1fr;
  }
}
</style>

<style lang="scss" scoped>

/* Add these new styles for How It Works section */
.how-it-works {
  background-color: #000000;
  padding: 1rem 0 2rem;
  color: white;
  margin: 0rem;
}

.section-header {
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0rem;
  color: #FFFFFF;
}

.gradient-bar {
  height: 6px;
  width: 100px;
  background: linear-gradient(90deg, #DC123E 60%, #FFFFFF 80%);
  border-radius: 3px;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.step-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 1rem;
}

.step-card {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 5rem 1rem 0rem;  /* Reduced padding */
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0.5rem;
}

.step-number {
  position: absolute;
  top: -15px; /* Moved up further from image */
  left: 50%;
  transform: translateX(-50%);
  width: 40px; /* Increased size slightly */
  height: 40px;
  background: linear-gradient(90deg, #DC123E 100%, #FFFFFF 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  z-index: 2; /* Ensure number stays above other elements */
}

.step-image-container {
  margin-bottom: 1rem; /* Reduced margin */
  border-radius: 0.5rem;
  overflow: hidden;
  height: 300px; /* Increased height */
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Changed to contain to match the illustration style */
    border-radius: 0.5rem;
    background-color: white; /* Added white background for illustrations */
  }
}

.step-image {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transition: transform 0.3s ease;
  object-fit: cover;

  &:hover {
    transform: scale(1.05);
  }
}

.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  min-height: auto; /* Removed fixed height */
  color: #FFFFFF;
}

.step-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0.5rem;
  flex-grow: 1; /* Allows description to fill available space */
}

@media (max-width: 992px) {
  .step-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .step-grid {
    grid-template-columns: 1fr;
  }

  .content-wrapper {
  position: relative;
  margin: 1rem;
  z-index: 1;
}

  .step-card {
    max-width: 400px;
    margin: 0 auto;
  }

  .section-title {
    font-size: 2rem;
  }
}
</style>